<template>
  <div class="root">
    <teleport to="body">
      <div class="modal">
        <div>
          <h2>{{ title }}</h2>
          <p>{{ msg }}</p>
          <button class="btn btn-success" @click="$emit('continue')">
            Fortsett
          </button>
          <button class="btn btn-danger" @click="$emit('abort')">Avbryt</button>
        </div>
      </div>
    </teleport>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TwoChoiceModal",
  props: ["title", "msg"],
  emits: ["continue", "abort"],
  setup() {
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
});
</script>
<style scoped>
.root {
  position: relative;
}
.modal {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
  height: calc(var("--vh", 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.modal > div {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
}
.btn {
  margin: 10px;
}
</style>
