<template>
  <div class="root">
    <teleport to="body">
      <div class="modal">
        <div>
          <h2>Endre status</h2>
          <div class="form-group">
            <input
              type="radio"
              id="ledig"
              value="Ledig"
              v-model="bookingStatus"
              @click="showWarning = true"
            />
            <label for="ledig">Ledig</label>
          </div>
          <div class="form-group">
            <input
              type="radio"
              id="reservert"
              value="Reservert"
              v-model="bookingStatus"
              @click="showWarning = false"
            />
            <label for="reservert">Reservert</label>
          </div>
          <div class="form-group last-option">
            <input
              type="radio"
              id="opptatt"
              value="Opptatt"
              v-model="bookingStatus"
              @click="showWarning = false"
            />
            <label for="opptatt">Opptatt</label>
          </div>
          <div v-if="showWarning" id="warning">
            <p>Obs. bookingen slettes hvis du fortsetter!</p>
          </div>
          <button
            class="btn btn-success"
            @click="$emit('continue', bookingStatus)"
          >
            Fortsett
          </button>
          <button class="btn btn-danger" @click="$emit('abort')">Avbryt</button>
        </div>
      </div>
    </teleport>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "EditStatusModal",
  props: ["booking"],
  emits: ["continue", "abort"],
  setup(props) {
    const bookingStatus = ref(props.booking.status);
    const showWarning = ref(false);

    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    return {
      bookingStatus,
      showWarning,
    };
  },
});
</script>
<style scoped>
.root {
  position: relative;
}
.modal {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
  height: calc(var("--vh", 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.modal > div {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
}
.btn {
  margin: 10px;
}

label {
  padding-left: 5px;
}
.last-option {
  margin-bottom: 30px;
}
#warning {
  color: rgb(255, 90, 0);
  width: 175px;
  margin: 0 auto;
}
</style>
