
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "EditStatusModal",
  props: ["booking"],
  emits: ["continue", "abort"],
  setup(props) {
    const bookingStatus = ref(props.booking.status);
    const showWarning = ref(false);

    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    return {
      bookingStatus,
      showWarning,
    };
  },
});
