<template>
  <base-card :lessPadding="true">
    <div>
      <h2>Bookinger som er reservert:</h2>
      <spinner v-if="isLoading" />
      <table v-if="!isLoading && isBookingsOnHold" class="table">
        <thead>
          <tr>
            <th>Dato</th>
            <th>Tid</th>
            <th>Område</th>
            <th>Navn</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="b in sortedBookingsOnHold" :key="b">
            <td @click="showBookingInfo(b)">{{ b.date }}</td>
            <td @click="showBookingInfo(b)">{{ b.time }}</td>
            <td @click="showBookingInfo(b)">{{ b.field }}</td>
            <td @click="showBookingInfo(b)">{{ b.name }}</td>
            <button class="remove-booking-button-temp" @click="openModal(b, 1)">
              <check-one theme="filled" size="24" fill="#006700" />
            </button>
            <button class="remove-booking-button-temp" @click="openModal(b, 0)">
              <handle-x theme="filled" size="24" fill="#B40000" />
            </button>
          </tr>
        </tbody>
      </table>
      <div v-if="!isLoading && !isBookingsOnHold">
        <p>Ingen reserverte bookinger for øyeblikket!</p>
      </div>
      <two-choice-modal
        v-if="modalinfo.isActive"
        :msg="modalinfo.msg"
        :title="modalinfo.title"
        @continue="continueBookingHandling"
        @abort="modalinfo.isActive = false"
      />
    </div>
  </base-card>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { CheckOne, HandleX } from "@icon-park/vue-next";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import firebase from "firebase";
import Spinner from "../UI/Spinner.vue";
import TwoChoiceModal from "../common/TwoChoiceModal.vue";
import BaseCard from "@/components/common/BaseCard.vue";

export default defineComponent({
  name: "BookingsOnHold",
  components: { Spinner, CheckOne, HandleX, TwoChoiceModal, BaseCard },
  setup() {
    const database = firebase.database();
    const store = useStore();
    const toast = useToast();
    const router = useRouter();

    const modalinfo = ref({
      msg: "",
      title: "",
      isActive: false,
    });

    watch([modalinfo.value], () => {
      if (modalinfo.value.isActive) {
        store.commit({
          type: "updateIsModalOpen",
          value: true,
        });
      } else {
        store.commit({
          type: "updateIsModalOpen",
          value: false,
        });
      }
    });

    const chosenTimes = ref([""]);
    const approveOrDecline = ref(3);
    const isLoading = ref(false);
    const isBookingsOnHold = ref(false);
    const bookingToBeHandled = ref({
      date: "",
      time: "",
      field: "",
      status: "",
      name: "",
      email: "",
      tlf: "",
      otherInfo: "",
    });

    const times = [
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
    ];

    const sortedBookingsOnHold = computed(() => {
      // sorterer bookingene så de havner kronologisk etter dato, så tidspunkt, så område
      const bookings = store.getters.getBookingsOnHold;
      const sortedBookings = bookings.sort((obj1: any, obj2: any) => {
        // lager dato-objekt av datoene
        const dateSplitObj1 = obj1.date.split(":");
        const dayObj1 = dateSplitObj1[0];
        const monthObj1 = dateSplitObj1[1] - 1;
        const yearObj1 = dateSplitObj1[2];
        const dateObj1 = new Date(yearObj1, monthObj1, dayObj1);
        const dateSplitObj2 = obj2.date.split(":");
        const dayObj2 = dateSplitObj2[0];
        const monthObj2 = dateSplitObj2[1] - 1;
        const yearObj2 = dateSplitObj2[2];
        const dateObj2 = new Date(yearObj2, monthObj2, dayObj2);

        // sorterer på dato
        if (dateObj1 > dateObj2) {
          return 1;
        } else if (dateObj1 < dateObj2) {
          return -1;
        }

        // sorterer på tid
        if (obj1.time > obj2.time) {
          return 1;
        } else if (obj1.time < obj2.time) {
          return -1;
        }

        // sorterer på område
        if (obj1.field > obj2.field) {
          return 1;
        } else if (obj1.field < obj2.field) {
          return -1;
        }
      });
      return sortedBookings;
    });

    const refreshTable = async () => {
      isLoading.value = true;
      store.commit("removeAllBookingsOnHold");
      await database
        .ref("bookings_on_hold")
        .get()
        .then((snapshot) => {
          if (snapshot.exists()) {
            if (store.getters.getBookingsOnHold.length === 0) {
              snapshot.forEach(function (childSnapshot) {
                store.commit({
                  type: "addBookingOnHold",
                  booking: childSnapshot.val(),
                });
              });
            }

            isBookingsOnHold.value = true;
          } else {
            isBookingsOnHold.value = false;
          }
        })
        .catch(() => {
          isBookingsOnHold.value = false;
          toast.error(
            "Henting av reserverte bookinger feilet. Refresh siden og ta kontakt om feilen vedvarer."
          );
          // console.error(
          //   "Feil ved henting av bookingsOnHold fra databasen",
          //   error
          // );
        });
      isLoading.value = false;
    };

    const showBookingInfo = (booking: any) => {
      store.commit({
        type: "newBookingToShow",
        date: booking.date,
        time: booking.time,
        field: booking.field,
        status: booking.status,
        name: booking.name,
        email: booking.email,
        tlf: booking.tlf,
        info: booking.otherInfo,
      });
    };

    const approveBooking = async () => {
      let noError = false;
      let bookingsOnHoldID =
        bookingToBeHandled.value.date +
        "_" +
        bookingToBeHandled.value.field +
        "_" +
        bookingToBeHandled.value.time;
      await database
        .ref("bookings_on_hold")
        .child(bookingsOnHoldID)
        .remove()
        .then(() => {
          noError = true;
        })
        .catch(() => {
          toast.error(
            "Godkjenning av booking feilet. Prøv igjen og ta kontakt om feilen vedvarer"
          );
          // console.error(
          //   "Feil under fjerning av bookingen fra bookingsOnHold-tabellen",
          //   error
          // );
        });

      if (noError) {
        chosenTimes.value.length = 0;
        if (bookingToBeHandled.value.time.includes("-")) {
          const timesplit = bookingToBeHandled.value.time.split(" - ");
          const fromTime = timesplit[0];
          const toTime = timesplit[1];

          // lager array med valgte tider
          times.forEach((time) => {
            if (time >= fromTime && time < toTime) {
              chosenTimes.value.push(time);
            }
          });
        } else {
          chosenTimes.value.push(bookingToBeHandled.value.time);
        }
        let updateOk = true;
        for (let i = 0; i < chosenTimes.value.length; i++) {
          await database
            .ref("all_bookings")
            .child(bookingToBeHandled.value.date)
            .child(bookingToBeHandled.value.field)
            .child(chosenTimes.value[i])
            .update({ status: "Opptatt" })
            .then(() => {
              // hvis alle er ok gjøres det ting i if-en under
            })
            .catch(() => {
              updateOk = false;
            });
        }

        if (updateOk) {
          toast.success("Bookingen er godkjent!");
          router.push({ path: "/blank" });
        } else {
          toast.error(
            "Godkjenning av booking feilet. Prøv igjen og ta kontakt om feilen vedvarer"
          );
          // console.error(
          //   "Feil under oppdatering av bookingen fra allBookings-tabellen",
          //   error
          // );
        }
      }
    };

    const declineBooking = async () => {
      let noError = false;
      let bookingsOnHoldID =
        bookingToBeHandled.value.date +
        "_" +
        bookingToBeHandled.value.field +
        "_" +
        bookingToBeHandled.value.time;

      await database
        .ref("bookings_on_hold")
        .child(bookingsOnHoldID)
        .remove()
        .then(() => {
          noError = true;
        })
        .catch(() => {
          toast.error(
            "Avvisning av booking feilet. Prøv igjen og ta kontakt om feilen vedvarer"
          );
          // console.error(
          //   "Feil under fjerning av bookingen fra bookingsOnHold-tabellen",
          //   error
          // );
        });
      if (noError) {
        chosenTimes.value.length = 0;
        if (bookingToBeHandled.value.time.includes("-")) {
          const timesplit = bookingToBeHandled.value.time.split(" - ");
          const fromTime = timesplit[0];
          const toTime = timesplit[1];

          // lager array med valgte tider
          times.forEach((time) => {
            if (time >= fromTime && time < toTime) {
              chosenTimes.value.push(time);
            }
          });
        } else {
          chosenTimes.value.push(bookingToBeHandled.value.time);
        }
        let updateOk = true;
        for (let i = 0; i < chosenTimes.value.length; i++) {
          await database
            .ref("all_bookings")
            .child(bookingToBeHandled.value.date)
            .child(bookingToBeHandled.value.field)
            .child(chosenTimes.value[i])
            .update({
              status: "Ledig",
              email: "",
              name: "",
              otherInfo: "",
              tlf: "",
              bookingTime: chosenTimes.value[i],
            })
            .then(() => {
              // hvis alle er ok gjøres det ting i if-en under
            })
            .catch(() => {
              updateOk = false;
            });
        }

        if (updateOk) {
          toast.success("Booking-avvisning gjennomført!");
          router.push({ path: "/blank" });
        } else {
          toast.error(
            "Avvisning av booking feilet. Prøv igjen og ta kontakt om feilen vedvarer"
          );
          // console.error(
          //   "Feil under oppdatering av bookingen fra allBookings-tabellen",
          //   error
          // );
        }
      }
    };

    const openModal = (booking: any, handleValue: number) => {
      bookingToBeHandled.value = booking;
      approveOrDecline.value = handleValue;

      if (handleValue === 1) {
        modalinfo.value.title = "Godkjenne booking";
        modalinfo.value.msg = "Er du sikker på at du vil godkjenne bookingen?";
      } else {
        modalinfo.value.title = "Avvis booking";
        modalinfo.value.msg = "Er du sikker på at du vil avvise bookingen?";
      }
      modalinfo.value.isActive = true;
    };

    const continueBookingHandling = async () => {
      if (approveOrDecline.value === 1) {
        await approveBooking();
      } else {
        await declineBooking();
      }
      modalinfo.value.isActive = false;
      router.push({ path: "/blank" });
    };

    onMounted(() => {
      refreshTable();
    });
    return {
      sortedBookingsOnHold,
      isLoading,
      isBookingsOnHold,
      approveBooking,
      declineBooking,
      showBookingInfo,
      modalinfo,
      openModal,
      continueBookingHandling,
    };
  },
});
</script>
<style scoped>
table {
  margin: 0 auto;
}
tbody > tr {
  cursor: pointer;
}
</style>
