
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { Edit } from "@icon-park/vue-next";
import firebase from "firebase";
import EditStatusModal from "../admin/EditStatusModal.vue";
import BaseCard from "@/components/common/BaseCard.vue";

export default defineComponent({
  name: "ProcessBooking",
  components: { Edit, EditStatusModal, BaseCard },
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const database = firebase.database();

    const validName = ref(false);
    const validEmail = ref(false);
    const validTlf = ref(false);
    const validInfo = ref(true);

    const isBookingToShow = ref(false);
    const formChanged = ref(false);
    const formReady = ref(false);
    const trigger = ref("");
    const chosenTimes = ref([""]);
    const times = [
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
    ];
    const bookingToShow = ref({
      date: "",
      time: "",
      field: "",
      status: "",
      name: "",
      email: "",
      tlf: "",
      info: "",
    });

    const modalinfo = ref({
      booking: bookingToShow.value,
      isActive: false,
    });

    watch([modalinfo.value], () => {
      if (modalinfo.value.isActive) {
        store.commit({
          type: "updateIsModalOpen",
          value: true,
        });
      } else {
        store.commit({
          type: "updateIsModalOpen",
          value: false,
        });
      }
    });

    const bookingFromStore = computed(() => {
      trigger.value;

      return store.getters.getBookingToShow;
    });

    watch([bookingFromStore.value, trigger], () => {
      if (bookingFromStore.value.date !== "") {
        const date = bookingFromStore.value.date;
        const time = bookingFromStore.value.time;
        const field = bookingFromStore.value.field;
        const status = bookingFromStore.value.status;
        const name = bookingFromStore.value.name;
        const email = bookingFromStore.value.email;
        const tlf = bookingFromStore.value.tlf;
        const info = bookingFromStore.value.info;

        bookingToShow.value.date = date;
        bookingToShow.value.time =
          time === "08:00 - 21:00" ? "HELE DAGEN" : time;
        bookingToShow.value.field = field;
        bookingToShow.value.status = status;
        bookingToShow.value.name = name;
        bookingToShow.value.email = email;
        bookingToShow.value.tlf = tlf;
        bookingToShow.value.info = info;

        isBookingToShow.value = true;
      } else {
        isBookingToShow.value = false;
      }
    });

    watch([bookingToShow.value], () => {
      const emailRegx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;
      const tlfRegex = /^\d{8}$/;

      if (bookingToShow.value.name.length > 5) {
        validName.value = true;
      } else {
        validName.value = false;
      }

      if (emailRegx.test(bookingToShow.value.email)) {
        validEmail.value = true;
      } else {
        validEmail.value = false;
      }

      if (tlfRegex.test(bookingToShow.value.tlf)) {
        validTlf.value = true;
      } else {
        validTlf.value = false;
      }

      if (!bookingToShow.value.info) {
        validInfo.value = true;
      } else if (bookingToShow.value.info.length < 200) {
        validInfo.value = true;
      } else {
        validInfo.value = false;
      }

      if (
        validName.value &&
        validEmail.value &&
        validTlf.value &&
        validInfo.value &&
        bookingToShow.value.status !== "Ledig"
      ) {
        formReady.value = true;
      } else {
        formReady.value = false;
      }
      if (
        formReady.value &&
        (bookingToShow.value.name !== bookingFromStore.value.name ||
          bookingToShow.value.email !== bookingFromStore.value.email ||
          bookingToShow.value.tlf !== bookingFromStore.value.tlf ||
          bookingToShow.value.info !== bookingFromStore.value.info ||
          bookingToShow.value.status !== bookingFromStore.value.status)
      ) {
        formChanged.value = true;
      } else {
        formChanged.value = false;
      }
    });

    const wholeDayBooking = async () => {
      let isAllGood = true;
      const fields = ["Bane 1", "Bane 2", "Bane 3", "Klubbhus"];

      if (bookingToShow.value.field === "HELE OMRÅDET") {
        for (let i = 0; i < fields.length; i++) {
          for (let j = 0; j < times.length; j++) {
            let updateTime =
              bookingToShow.value.status === "Ledig"
                ? times[j]
                : "08:00 - 21:00";
            await database
              .ref("all_bookings")
              .child(bookingToShow.value.date)
              .child(fields[i])
              .child(times[j])
              .update({
                name: bookingToShow.value.name,
                email: bookingToShow.value.email,
                tlf: bookingToShow.value.tlf,
                otherInfo: bookingToShow.value.info,
                status: bookingToShow.value.status,
                bookingTime: updateTime,
              })
              .then()
              .catch(() => {
                isAllGood = false;
                toast.error(
                  "Oppdatering feilet! Prøv igjen og ta kontakt om feilen vedvarer."
                );
                // console.error(
                //   "Feil under oppdatering av bookingen fra allBookings-tabellen",
                //   error
                // );
              });
          }
        }
      } else {
        for (let j = 0; j < times.length; j++) {
          let updateTime =
            bookingToShow.value.status === "Ledig" ? times[j] : "08:00 - 21:00";
          await database
            .ref("all_bookings")
            .child(bookingToShow.value.date)
            .child(bookingToShow.value.field)
            .child(times[j])
            .update({
              name: bookingToShow.value.name,
              email: bookingToShow.value.email,
              tlf: bookingToShow.value.tlf,
              otherInfo: bookingToShow.value.info,
              status: bookingToShow.value.status,
              bookingTime: updateTime,
            })
            .then()
            .catch(() => {
              isAllGood = false;
              toast.error(
                "Oppdatering feilet! Prøv igjen og ta kontakt om feilen vedvarer."
              );
              // console.error(
              //   "Feil under oppdatering av bookingen fra allBookings-tabellen",
              //   error
              // );
            });
        }
      }
      if (isAllGood) {
        let ok = true;

        let fields = [];
        if (bookingToShow.value.field === "HELE OMRÅDET") {
          fields = ["Bane 1", "Bane 2", "Bane 3", "Klubbhus"];
        } else {
          fields.push(bookingToShow.value.field);
        }

        for (let i = 0; i < fields.length; i++) {
          let bookingsToShowID =
            bookingToShow.value.date + "_" + fields[i] + "_" + "08:00 - 21:00";

          if (bookingToShow.value.status === "Reservert") {
            await database
              .ref("bookings_on_hold")
              .child(bookingsToShowID)
              .update({
                date: bookingToShow.value.date,
                field: fields[i],
                time: "08:00 - 21:00",
                name: bookingToShow.value.name,
                email: bookingToShow.value.email,
                tlf: bookingToShow.value.tlf,
                otherInfo: bookingToShow.value.info,
                status: bookingToShow.value.status,
              })
              .catch(() => {
                ok = false;
              });
          } else {
            await database
              .ref("bookings_on_hold")
              .child(bookingsToShowID)
              .remove()
              .catch(() => {
                ok = false;
              });
          }
        }

        if (ok) {
          store.commit("clearBookingToShow");
          toast.success("Oppdatering vellykket!");
          router.push({ path: "/blank" });
        } else {
          toast.error(
            "Oppdatering feilet! Prøv igjen og ta kontakt om feilen vedvarer."
          );
        }
      }
    };

    const updateBooking = async () => {
      // dersom info ikke er utfylt, blir den satt til en tom string for å unngå feil i oppdateringen
      if (!bookingToShow.value.info) {
        bookingToShow.value.info = "";
      }

      if (bookingToShow.value.time === "HELE DAGEN") {
        wholeDayBooking();
      } else {
        let noError = false;
        let bookingsToShowID =
          bookingToShow.value.date +
          "_" +
          bookingToShow.value.field +
          "_" +
          bookingToShow.value.time;

        if (bookingToShow.value.status == "Reservert") {
          await database
            .ref("bookings_on_hold")
            .child(bookingsToShowID)
            .update({
              date: bookingToShow.value.date,
              field: bookingToShow.value.field,
              time: bookingToShow.value.time,
              name: bookingToShow.value.name,
              email: bookingToShow.value.email,
              tlf: bookingToShow.value.tlf,
              otherInfo: bookingToShow.value.info,
              status: bookingToShow.value.status,
            })
            .then(() => {
              noError = true;
            })
            .catch(() => {
              toast.error(
                "Oppdatering feilet! Prøv igjen og ta kontakt om feilen vedvarer."
              );
              // console.error(
              //   "Feil under oppdatering av bookingen i bookingsOnHold-tabellen",
              //   error
              // );
            });
        } else {
          await database
            .ref("bookings_on_hold")
            .child(bookingsToShowID)
            .remove()
            .then(() => {
              noError = true;
            })
            .catch(() => {
              toast.error(
                "Oppdatering feilet! Prøv igjen og ta kontakt om feilen vedvarer"
              );
              // console.error(
              //   "Feil under fjerning av bookingen fra bookingsOnHold-tabellen",
              //   error
              // );
            });
        }

        if (noError) {
          chosenTimes.value.length = 0;
          if (bookingToShow.value.time.includes("-")) {
            const timesplit = bookingToShow.value.time.split(" - ");
            const fromTime = timesplit[0];
            const toTime = timesplit[1];

            // lager array med valgte tider
            times.forEach((time) => {
              if (time >= fromTime && time < toTime) {
                chosenTimes.value.push(time);
              }
            });
          } else {
            chosenTimes.value.push(bookingToShow.value.time);
          }
          let updateOk = true;
          for (let i = 0; i < chosenTimes.value.length; i++) {
            let updateTime =
              bookingToShow.value.status === "Ledig"
                ? chosenTimes.value[i]
                : bookingToShow.value.time;
            await database
              .ref("all_bookings")
              .child(bookingToShow.value.date)
              .child(bookingToShow.value.field)
              .child(chosenTimes.value[i])
              .update({
                name: bookingToShow.value.name,
                email: bookingToShow.value.email,
                tlf: bookingToShow.value.tlf,
                otherInfo: bookingToShow.value.info,
                status: bookingToShow.value.status,
                bookingTime: updateTime,
              })
              .then(() => {
                // hvis alle er ok gjøres det ting i if-en under
              })
              .catch(() => {
                updateOk = false;
              });
          }

          if (updateOk) {
            store.commit("clearBookingToShow");
            toast.success("Oppdatering vellykket!");
            router.push({ path: "/blank" });
          } else {
            toast.error(
              "Oppdatering feilet! Prøv igjen og ta kontakt om feilen vedvarer."
            );
            // console.error(
            //   "Feil under oppdatering av bookingen fra allBookings-tabellen"
            // );
          }
        }
      }
    };

    const editStatus = (status: string) => {
      bookingToShow.value.status = status;

      if (status === "Ledig") {
        bookingToShow.value.name = "";
        bookingToShow.value.email = "";
        bookingToShow.value.tlf = "";
        bookingToShow.value.info = "";
        updateBooking();
      }
      modalinfo.value.isActive = false;
    };

    onMounted(() => {
      trigger.value = "1";
    });

    return {
      isBookingToShow,
      bookingToShow,
      formChanged,
      formReady,
      validName,
      validEmail,
      validTlf,
      validInfo,
      modalinfo,
      editStatus,
      updateBooking,
    };
  },
});
