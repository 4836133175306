<template>
  <h1 class="page-title">Finstadbru Hundesport Admin</h1>
  <div class="back-button">
    <button class="btn" @click="toFrontpage()">
      <back theme="filled" size="24" fill="#2c3e50" />
      <strong class="button-text">Tilbake</strong>
    </button>
  </div>
  <div class="wrapper">
    <div class="one"><bookings-on-hold /></div>
    <div class="two"><process-booking /></div>
    <div class="three">
      <pick-date
        :title="pickDateTitle"
        :limit="dateLimit"
        :adminCss="'admin-css'"
      />
    </div>
    <div class="four">
      <see-bookings :title="seeBookingsTitle" :isBookingPage="false" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Back } from "@icon-park/vue-next";
import { useRouter } from "vue-router";
import BookingsOnHold from "../../components/admin/BookingsOnHold.vue";
import ProcessBooking from "../../components/admin/ProcessBooking.vue";
import PickDate from "../../components/common/PickDate.vue";
import SeeBookings from "../../components/common/SeeBookings.vue";

export default defineComponent({
  name: "Admin",
  components: {
    BookingsOnHold,
    ProcessBooking,
    PickDate,
    SeeBookings,
    Back,
  },
  setup() {
    const pickDateTitle = "Velg dag:";
    const seeBookingsTitle = "Velg booking:";
    const dateLimit = null;
    const router = useRouter();

    const toFrontpage = () => {
      router.push({ path: "/fhbooking" });
    };

    return {
      pickDateTitle,
      seeBookingsTitle,
      dateLimit,
      toFrontpage,
    };
  },
});
</script>

<style scoped>
.back-button {
  display: inline-block;
  margin: 0 3% 3% 3%;
  text-align: left;
}
.button-text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5%;
}

@media screen and (min-width: 992px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(550px, auto);
  }
  .one {
    grid-column: 1;
    grid-row: 1;
  }
  .two {
    grid-column: 2;
    grid-row: 1;
  }
  .three {
    grid-column: 1;
    grid-row: 2;
    margin-top: 1rem;
  }
  .four {
    grid-column: 2;
    grid-row: 2;
    margin-top: 1rem;
  }
}
</style>
