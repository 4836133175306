
import { defineComponent } from "vue";

export default defineComponent({
  name: "TwoChoiceModal",
  props: ["title", "msg"],
  emits: ["continue", "abort"],
  setup() {
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
});
