
import { defineComponent } from "vue";
import { Back } from "@icon-park/vue-next";
import { useRouter } from "vue-router";
import BookingsOnHold from "../../components/admin/BookingsOnHold.vue";
import ProcessBooking from "../../components/admin/ProcessBooking.vue";
import PickDate from "../../components/common/PickDate.vue";
import SeeBookings from "../../components/common/SeeBookings.vue";

export default defineComponent({
  name: "Admin",
  components: {
    BookingsOnHold,
    ProcessBooking,
    PickDate,
    SeeBookings,
    Back,
  },
  setup() {
    const pickDateTitle = "Velg dag:";
    const seeBookingsTitle = "Velg booking:";
    const dateLimit = null;
    const router = useRouter();

    const toFrontpage = () => {
      router.push({ path: "/fhbooking" });
    };

    return {
      pickDateTitle,
      seeBookingsTitle,
      dateLimit,
      toFrontpage,
    };
  },
});
